<template>
    <div id="role-manage">
        角色管理
    </div>
</template>

<script>
export default {
    name: 'RoleManage', // 角色管理
}
</script>

<style lang="less" scoped>
</style>